import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat, displayDateFormat, assetTypeValues, displayDateFormatShort } from '../../../constants';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, CircularProgress, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Link, Tabs, Tab } from '@material-ui/core';
import { DropzoneArea } from "material-ui-dropzone";
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp, AutocompleteCreatable, ExportMenu } from '../../shared_elements';
import { TechSpecsNav } from '../../elements'
import { AssetInfo, AddLLP } from '../components'
import EditIcon from '@material-ui/icons/Edit';
import { globalGetService, globalPutService, globalDeleteService, globalExportService, globalFileUploadService } from '../../../globalServices';
import { checkApiStatus, checkPermission, replaceEmptyNumber, replaceEmptyNumberZero } from '../../utils_v2';
import { downloadFileType } from '../../../utils';
import { Skeleton } from "@material-ui/lab";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from "../../../constants/regEx";
import { getPermissionKey } from "..";
import {EngineLlpList} from "../components/EngineLlpList";
const LLPsHd = ({ params, editField }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell><div style={{width:'50px'}} >Module</div></TableCell>
                <TableCell><div style={{width:'100px'}}>Description</div></TableCell>
                <TableCell><div style={{width:'50px'}}>P/N</div></TableCell>
                <TableCell><div style={{width:'50px'}}>S/N</div></TableCell>
                <TableCell><div style={{width:['apu', 'lg', 'propeller'].includes(params.type)?'95px':''}}>TSN</div></TableCell>
                {['apu', 'lg', 'propeller'].includes(params?.type) ?
                    <TableCell><div style={{width:['apu', 'lg', 'propeller'].includes(params.type)?'95px':''}}>TSO</div></TableCell> : null
                }
                <TableCell><div style={{width:['apu', 'lg', 'propeller'].includes(params.type)?'95px':''}}>CSN</div></TableCell>
                {['apu', 'lg', 'propeller'].includes(params?.type) ?
                    <TableCell><div style={{width:['apu', 'lg', 'propeller'].includes(params.type)?'95px':''}}>CSO</div></TableCell> : null
                }
                <TableCell><div style={{width:['apu', 'lg', 'propeller'].includes(params.type)?'100px':''}}>{['propeller'].includes(params?.type) ? 'Interval Hours' : 'Cycle Limit'}</div></TableCell>
                <TableCell><div style={{width:['apu', 'lg', 'propeller'].includes(params.type)?'105px':''}}>{['propeller'].includes(params?.type) ? 'Remaining Hours' : 'Remaining Cycle'}</div></TableCell>
                <TableCell><div style={{width:'100px'}}>LLPs as of Date</div></TableCell>
                <TableCell><div >Remarks</div></TableCell>
                {!editField ?
                    <TableCell>Action</TableCell> : null
                }
            </TableRow>
        </TableHead>
    )
}
const LLPsList = ({ item, editField, onFieldChange, sheetError, setSheetError, onResetError, params, onDelete }) => {
    const [showMore, setShowMore] = useState({});
    const StatusCode = item.part_numbers_dropdown?.filter((option => option.part_number === item?.part_number))[0]?.status;
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    const calculateWidth = (minWidth, maxWidth, content, minCount, maxCount) => {
        let width = 50;
        if (content?.length < minCount ) {
            width = minWidth;
        } else if (content?.length > minCount) {
            width = maxWidth;
        }

        return `${width}px`;
    }
    return (
        <TableRow>
            <TableCell><div style={{ width:['apu', 'lg', 'propeller'].includes(params.type)? calculateWidth('60', '120', item?.module, '10', ''):null }}>{item?.module || "--"}</div></TableCell>
            <TableCell>
                {item?.description && item?.description?.length > 25 ? (
                     <div style={{ width:['apu', 'lg', 'propeller'].includes(params?.type) ? calculateWidth('80', '150', item?.description, '26', ''):'' }}>
                        {showMore[item?.field] ? (
                            <span> {item?.description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > less </a>
                            </span>
                        ) : (
                            <span> {item.description.substring(0, 20)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : (
                    <div style={{ width:['apu', 'lg', 'propeller'].includes(params?.type) ? calculateWidth('80', '100', item?.description, '10', ''):'' }}>{item.description}</div> || '--'
                )}
            </TableCell>
            {!['apu', 'lg', 'propeller'].includes(params?.type) ?
                <TableCell>{editField ?
                    <>
                        {(() => {

                            let optionValue = item?.part_number && item?.part_numbers_dropdown?.length ? item.part_numbers_dropdown.find((pn) => pn.part_number === item.part_number) : null;
                            if ((optionValue === null || optionValue === undefined) && item.part_number) {
                                optionValue = { part_number: item.part_number }
                            }
                            let options = item?.part_number && item?.part_numbers_dropdown?.length ? item.part_numbers_dropdown.filter((option => option.part_number !== item?.part_number)) : []
                            return (
                                <AutocompleteCreatable
                                    options={options}
                                    paramsKey='part_number'
                                    optionKey='part_number'
                                    value={optionValue?.part_number || ''}
                                    multiple={false}
                                    onFieldChange={(e, paramsKey, newValue) => onFieldChange('part_number', newValue)}
                                    autoCompleteWidth={"140px"}
                                    disableClearable={true}
                                    filterSelectedOption={true}
                                    variant="standard"
                                />
                            )
                        })()}
                    </>
                    :
                   <div>{item?.part_number || "--"}</div>}
                    {StatusCode === 2 ? <span style={{ border: "1px solid #a78701", backgroundColor: "#fff1b6", fontSize: "12px", width: "120px", display: "block", fontFamily: "Conv_IBMPlexSans-Regular", borderRadius: "4px", padding: "2px 2px" }}>Note: LLP under approval process.</span> : ''}
                </TableCell> :
                <TableCell>{editField ?
                    <TextField
                        required
                        value={item?.part_number}
                        onChange={(e) => onFieldChange('part_number', e.target.value)}
                        //style={{ width: "80px" }}
                        variant="standard"
                        error={sheetError?.part_number ? true : false}
                        helperText={sheetError?.part_number || ''}
                        onFocus={() => setSheetError({ ...sheetError, 'part_number': '' })}
                        inputProps={{ maxLength: 15 }}
                    /> :<div style={{ width:['apu', 'lg', 'propeller'].includes(params?.type) ? calculateWidth('85', '180', item?.part_number, '14', ''):'' }}>{item.part_number||'--'}</div>}
                </TableCell>}
            <TableCell>{editField ?
                <TextField
                    style={{ width: "80px" }}
                    value={item?.serial_number}
                    inputProps={{ maxLength: 15 }}
                    error={sheetError?.serial_number ? true : false}
                    helperText={sheetError?.serial_number || ''}
                    onFocus={() => setSheetError({ ...sheetError, 'serial_number': '' })}
                    onChange={(e) => onFieldChange('serial_number', e.target.value)}
                /> :
               <div style={{ width:['apu', 'lg', 'propeller'].includes(params?.type) ? calculateWidth('100', '180', item?.serial_number, '14', ''):'' }}>{item?.serial_number || ''}</div>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "80px" }}
                    value={item?.tsn}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                /> :
                <div >{item?.tsn ? (item?.tsn).toFixed(2) : '0'}</div>}
            </TableCell>
            {['apu', 'lg', 'propeller'].includes(params?.type) ?
                <TableCell>{editField && ['apu', 'lg', 'propeller'].includes(params?.type) ?
                    <TextField
                        style={{ width: "80px" }}
                        value={item?.tso}
                        inputProps={{ maxLength: 10 }}
                        onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tso', e.target.value) : e.preventDefault()}
                    /> :
                    <div>{item?.tso || '0'}</div>}
                </TableCell> : null}
            <TableCell>{editField ?
                <TextField
                    error={sheetError?.csn ? true : false}
                    helperText={sheetError?.csn || ''}
                    style={{ width: "80px" }}
                    value={item?.csn}
                    onFocus={() => setSheetError({ ...sheetError, 'csn': '' })}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                /> :
                <div>{item?.csn ? (item?.csn) : '0'}</div>}
            </TableCell>
            {['apu', 'lg', 'propeller'].includes(params?.type) ?
                <TableCell>{editField ?
                    <TextField
                        style={{ width: "80px" }}
                        value={item?.cso}
                        inputProps={{ maxLength: 10 }}
                        onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cso', e.target.value) : e.preventDefault()}
                    /> :
                    <div>{item?.cso ? (item?.cso) : '0'}</div>}
                </TableCell> : null}
            <TableCell>
                {editField && item.part_numbers_dropdown?.filter((pn) => pn.part_number === item.part_number).length === 0 || editField && ['apu', 'lg', 'propeller'].includes(params?.type) ?
                    <TextField
                        error={sheetError?.cycles_max_limit ? true : false}
                        helperText={sheetError?.cycles_max_limit || ''}
                        style={{ width: "80px" }}
                        value={item?.cycles_max_limit}
                        onFocus={() => setSheetError({ ...sheetError, 'cycles_max_limit': '' })}
                        onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cycles_max_limit', e.target.value) : e.preventDefault()}
                    /> :
                    <>
                        <div>{item?.cycles_max_limit ? item?.cycles_max_limit : '0'}</div>
                        <p className="error-msg">{sheetError?.cycles_max_limit || ''}</p>
                    </>
                }
            </TableCell>
            <TableCell>
                {editField && item.part_numbers_dropdown?.filter((pn) => pn.part_number === item.part_number).length === 0 || editField && ['apu', 'lg', 'propeller'].includes(params?.type) ?
                    <TextField
                        error={sheetError?.cycles_remaining ? true : false}
                        helperText={sheetError?.cycles_remaining || ''}
                        style={{ width: "80px" }}
                        value={item?.cycles_remaining}
                        onFocus={() => setSheetError({ ...sheetError, 'cycles_remaining': '' })}
                        onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cycles_remaining', e.target.value) : e.preventDefault()}
                    /> :
                    <>
                        <div>{item?.cycles_remaining || '0'}</div>
                        <p className="error-msg">{sheetError?.cycles_remaining || ''}</p>
                    </>
                }
            </TableCell>
            {!['apu', 'lg', 'propeller'].includes(params?.type) ?
                <TableCell>{editField ? (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            style={{ width: "130px" }}
                            name="sheet_date"
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true }}
                            value={item?.sheet_date || null}
                            onChange={(data, value) => { onFieldChange('sheet_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('sheet_date') }}
                            error={sheetError?.sheet_date ? true : false}
                            helperText={sheetError?.sheet_date || ''}
                        />
                    </MuiPickersUtilsProvider>
                ) : (
                    moment(item?.sheet_date).isValid() ? moment(item?.sheet_date).format(displayDateFormatShort) : "--"
                )}
                </TableCell> :
                <TableCell>{editField ? (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            style={{ width: "130px" }}
                            name="as_of_date"
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true }}
                            value={item?.as_of_date || null}
                            onChange={(data, value) => { onFieldChange('as_of_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('as_of_date') }}
                            error={sheetError?.as_of_date ? true : false}
                            helperText={sheetError?.as_of_date || ''}
                        />
                    </MuiPickersUtilsProvider>
                ) : (
                    moment(item?.as_of_date).isValid() ? moment(item?.as_of_date).format(displayDateFormatShort) : "--"
                )}
                </TableCell>}
            <TableCell>{editField ?
                <TextField
                    inputProps={{ maxLength: 225}}
                    value={item?.remarks}
                    onChange={(e) => onFieldChange('remarks', e.target.value)}
                    multiline
                    rows={2}
                /> :
                
               ( item?.remarks && item?.remarks?.length > 25 ? (
                     <div style={{ width: calculateWidth('100', '142', item?.remarks, '25', '') }}>
                        {showMore[item?.field] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 20)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : (
                    <span>{item.remarks || '--'}</span>
                ))} 
            </TableCell>
            {!editField && ['apu', 'lg', 'propeller'].includes(params?.type) && checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?

                <TableCell>
                    <ul className="list-inline">
                        <li className="list-inline-item hover-inline " onClick={onDelete}> <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip></li>
                    </ul>
                </TableCell> : null}
        </TableRow>
    )
}

const ImportLLP = ({ llpsInfo, params, getResponseBack, onExportLlp, tabIndex }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);
    const onImportLlp = (loaderType) => {
        var formData = new FormData();
        formData.append('files', files[0]);
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalFileUploadService(`console/v2/asset/${params.type}/${params.aircraft_slug}/${tabIndex=='1'? 'llp-secondary':'llp'}/import/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setModal(false); getResponseBack();
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    return (
        <>
            <Button onClick={() => setModal(true)} color='primary' size='small' variant="contained">Import</Button>
            <Dialog
                open={modal}
                onClose={() => setModal(false)}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Import LLP(s)
                </DialogTitle>
                <DialogContent style={{ width: "380px" }} dividers={true}>
                    <div >
                        <DropzoneArea
                            acceptedFiles={['.xls, .xlsx,']}
                            filesLimit={1}
                            maxFileSize={104857600}
                            showPreviewsInDropzone={false}
                            useChipsForPreview={true}
                            showPreviews={true}
                            dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                            dropzoneClass="drag-drop-cnt"
                            maxWidth="xl"
                            showAlerts={['info', 'error']}
                            alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                            clearOnUnmount={true}
                            onChange={(files) => setFiles(files)}
                        />
                    </div>
                    <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Supported extension ('.xls, .xlsx').</span>
                    {!llpsInfo?.list?.length ?
                        <Grid>
                            <Link style={{ color: 'blue', fontSize: '11px', textDecoration: 'underline' }} type="link" onClick={(extension) => onExportLlp(extension = 'xlsx')}>Download Sample</Link>
                        </Grid> : <Grid><span style={{backgroundColor: "#fff1b6", fontSize: '11px'}} >Export the LLP list, update the same and import here</span> </Grid>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={() => setModal(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading || files.length === 0} onClick={onImportLlp} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

const AddEditLLP = ({ params, addEditLlp, toggleModalFn, getResponseBack, assetInfoData, tabIndex }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [sheetError, setSheetError] = useState([]);
    const [llps, setLlps] = useState(addEditLlp.llps);
    const onAddEditLlp = (loaderType) => {

        let llpsError = [], isLLPsError = true;
        llpsError = llps?.map((llp) => {
            return {
                module: llp?.module ? '' : 'Please enter Module',
                description: llp?.description ? '' : 'Please enter Description',
                part_number: llp?.part_number ? '' : 'Please enter Part number',
                as_of_date: !llp.as_of_date || moment(llp.as_of_date).isValid() && moment(llp.as_of_date).isSameOrBefore(moment(), 'day') ? '' : "LLPs as of date can't be future Date",
                sheet_date: !llp.sheet_date || moment(llp.sheet_date).isValid() && moment(llp.sheet_date).isSameOrBefore(moment(), 'day') ? '' : "LLPs as of date can't be future Date",
                csn: llp?.csn?.toString()?.trim()?.length ? '' : 'Please enter csn',
                cycles_max_limit: llp?.cycles_max_limit >= 0 ? '' : 'Please enter Cycle Limit',
                cycles_remaining: llp?.cycles_remaining?.toString()?.trim()?.length ? llp?.cycles_remaining >= 0 ? '' : "Cycle Remaining can't be negative value" : 'Please enter Cycle Remaining'
            }
        })
        llpsError.map(item => {
            if (!Object.keys(item).every((k) => { return item[k] === '' })) {
                isLLPsError = false;
            }
        });
        if (isLLPsError) {
            let payload = llps.map(llp => replaceEmptyNumberZero(llp, ['serial_number', 'tsn', 'csn', 'cso', 'tso', 'cycles_max_limit', 'cycles_remaining']));
            payload = payload.map(item=>(
                {
                ...item, module: item?.module?.value ?item?.module?.label :item?.module
            }))
            setLoading(true);
            let url = tabIndex == '1' ? `console/v2/asset/${params.type}/${params.aircraft_slug}/llp-secondary/` : `console/${params.type}/${assetInfoData.id}/llp/`
            globalPutService(url, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
        } else {
            setSheetError(llpsError)
        }
    }

    const onFieldChange = (key, value, index) => {
        if (!['apu', 'lg', 'propeller'].includes(params?.type) && (key === "part_number")) {
            setLlps(llps.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [key]: value?.part_number || null, serial_number: '', tsn: '0', csn: value?.csn || '0', cycles_max_limit: value?.cycles_limit || '', cycles_remaining: value?.cycles_remaining || '', sheet_date: null }))
        } else if (!['propeller'].includes(params?.type) && (key === 'csn')) {
            let itemData = llps[index];
            let cyclesRemaining = itemData.cycles_max_limit - value
            if (cyclesRemaining !== null && cyclesRemaining !== 0) {
                setLlps(llps.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [key]: value, cycles_remaining: cyclesRemaining }));
            } else {
                setLlps(llps.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [key]: value, cycles_remaining: cyclesRemaining }));
            }
        } else if (!['propeller'].includes(params?.type) && (key === 'cycles_max_limit')) {
            let itemData = llps[index];
            let newCyclesRemaining = itemData.cycles_remaining - (itemData.cycles_max_limit - value);
            setLlps(llps.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [key]: value, cycles_remaining: newCyclesRemaining }));
        } else {
            setLlps(llps.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [key]: value }));
        }
    }
    return (
        <Dialog
            open={addEditLlp.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            fullScreen
        >
            <DialogTitle id="scroll-dialog-title">
            Edit LLP
            </DialogTitle>
            <DialogContent dividers={true}>
                { ['engine','hpc','hpt','fan','lpt'].includes(params?.type) ?
                <Paper>
                    <Table className='mui-table-format'>
                        <LLPsHd params={params} editField={true} />
                        <TableBody>
                            {llps?.map((item, index) =>
                                <EngineLlpList
                                    key={index}
                                    item={item}
                                    params={params}
                                    editField={true}
                                    onFieldChange={(key, value) => onFieldChange(key, value, index)}
                                    sheetError={sheetError[index] ? sheetError[index] : null}
                                    setSheetError={setSheetError}
                                    onResetError={(key) => sheetError?.length ? setSheetError(sheetError.map((errorItem, errorIndex) => index !== errorIndex ? errorItem : { ...errorItem, [key]: '' })) : setSheetError([])}
                                />
                            )}
                        </TableBody>
                    </Table>
                </Paper>:
                <Paper>
                    <Table className='mui-table-format'>
                        <LLPsHd params={params} editField={true} />
                        <TableBody>
                            {llps?.map((item, index) =>
                                <LLPsList
                                    key={index}
                                    item={item}
                                    params={params}
                                    editField={true}
                                    onFieldChange={(key, value) => onFieldChange(key, value, index)}
                                    sheetError={sheetError[index] ? sheetError[index] : null}
                                    setSheetError={setSheetError}
                                    onResetError={(key) => sheetError?.length ? setSheetError(sheetError.map((errorItem, errorIndex) => index !== errorIndex ? errorItem : { ...errorItem, [key]: '' })) : setSheetError([])}
                                />
                            )}
                        </TableBody>
                    </Table>
                </Paper>}
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditLlp} color='primary' size='small' variant='contained'>{isLoading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}
const LLPs = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [llpsInfo, setLlpsInfo] = useState({ list: [], pagination: {} });
    const [addEditLlp, setAddEditLlp] = useState({ modal: false, data: null });
    const [deleteLlp, setDeleteLlp] = useState({ modal: false, data: null });
    const [addLlp, setAddLlp] = useState({ modal: false, data: null })
    const [assetInfoData, setAssetInfoData] = useState();
    const [tabIndex, setTabIndex] = useState('0');
    useEffect(() => {
        getLlps({}, 'skeletonLoader');
        getAssetInfo('setAssetSkeletonLoader');
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }

    const getLlps = (query = {}, loaderType, urlendpoint='llp') => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/${(tabIndex=='1' && urlendpoint != 'llp') || urlendpoint =='llp-secondary'? 'llp-secondary':'llp'}/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLlpsInfo(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteLlp = () => {
        setLoading(true);
        globalDeleteService(`console/v2/asset/${params.type}/${params.aircraft_slug}/${tabIndex=='1'? 'llp-secondary':'llp'}/${deleteLlp.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setDeleteLlp({ modal: false, data: null });
                    getLlps({}, 'pageLoader',tabIndex=='1'? 'llp-secondary':'llp');
                }
                setLoading(false);
            })
    }

    const onExportLlp = (extension, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalExportService(`console/v2/asset/${params.type}/${params.aircraft_slug}/${tabIndex=='1'? 'llp-secondary':'llp'}/`, { download: extension })
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label} ${assetInfoData.serial_number} LLP_List ${moment().format(fieldDateFormat)}`, extension)
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const getFullLlps = () => {
        setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/${tabIndex=='1'? 'llp-secondary':'llp'}/`, { page: 1, per_page: llpsInfo?.pagination?.total || 10 })
            .then(response => {
                if (checkApiStatus(response)) {
                    setAddEditLlp({ modal: true, llps: response?.data?.data?.list || [] })
                }
                setLoading(false);
            })
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo
                assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()}
            />
            <Paper className="tech-specs-card"  style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content" >
                {['engine','hpc','hpt','fan','lpt'].includes(params?.type) ?
                <Paper square style={{marginBottom:'10px'}}>
                    <Tabs variant='fullWidth' indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => {setTabIndex(newValue); getLlps({},'skeletonLoader', newValue =='1'?'llp-secondary':'llp') }} aria-label="simple tabs example">
                        <Tab style={{textTransform:'none'}} label="Primary LLP" value='0' />
                        <Tab style={{textTransform:'none'}} label="Secondary LLP" value='1' />
                    </Tabs>
                </Paper>: null}
                    <div style={{ padding: '10px' }}>
                        {skeletonLoader ? <STableLoader count={10} /> :
                            <>
                                <Paper square style={{ padding: '4px 8px' }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item md={8}></Grid>
                                        <Grid item md={4}>
                                            <ul className="list-inline flex-centered" style={{ float: 'right' }}>

                                                {checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                                                    <>
                                                        <li className="list-inline-item hover-inline" >
                                                            <Tooltip title='Edit' arrow><EditIcon onClick={getFullLlps} color="primary" fontSize="small" /></Tooltip>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <ImportLLP tabIndex={tabIndex} onExportLlp={onExportLlp} llpsInfo={llpsInfo} params={params} getResponseBack={() => getLlps({}, 'pageLoader',tabIndex=='1'? 'llp-secondary':'llp')} />
                                                        </li>
                                                    </> : null
                                                }
                                                {llpsInfo?.list?.length && checkPermission('technical_specs', getPermissionKey(params.type), 'EXP') ?
                                                    <li className='list-inline-item'>
                                                        <ExportMenu
                                                            files={[{ title: 'Excel', extension: 'xlsx' },]}
                                                            exportReportFn={(file) => onExportLlp(file.extension)}
                                                        />
                                                    </li> : null
                                                }
                                                { checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                                                    <li className="list-inline-item hover-inline" >
                                                        <Button size="small" variant="outlined" color="primary" onClick={() => setAddLlp({ modal: true, data: null })}>ADD LLP</Button>
                                                    </li> : null
                                                }
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                {
                                    ['engine','hpc','hpt','fan','lpt'].includes(params?.type) ?
                                        <Paper style={{ maxHeight: window.innerHeight - 290 + 'px', overflow: "auto" }}>
                                            <Table className='mui-table-format' stickyHeader>
                                                <LLPsHd params={params} />
                                                <TableBody>
                                                    {llpsInfo.list.map((item, index) =>
                                                        <EngineLlpList
                                                            params={params}
                                                            key={index}
                                                            item={item}
                                                            onEdit={() => setAddEditLlp({ modal: true, mode: 'edit', data: item })}
                                                            editField={false}
                                                            onDelete={() => setDeleteLlp({ modal: true, data: item })}
                                                        />
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {/* </div> */}
                                        </Paper> :
                                        <Paper style={{ maxHeight: window.innerHeight - 290 + 'px', overflow: "auto" }}>
                                            <Table className='mui-table-format' stickyHeader>
                                                <LLPsHd params={params} />
                                                <TableBody>
                                                    {llpsInfo.list.map((item, index) =>
                                                        <LLPsList
                                                            params={params}
                                                            key={index}
                                                            item={item}
                                                            onEdit={() => setAddEditLlp({ modal: true, mode: 'edit', data: item })}
                                                            editField={false}
                                                            onDelete={() => setDeleteLlp({ modal: true, data: item })}

                                                        />
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {/* </div> */}
                                        </Paper>
                                }
                                <Paper>
                                        <Pagination
                                            pagination={llpsInfo?.pagination}
                                            onChangePage={(event, newPage) => getLlps({ ...filter, page: newPage + 1, per_page: llpsInfo?.pagination.per_page }, 'pageLoader',tabIndex=='1'? 'llp-secondary':'llp')}
                                            onChangeRowsPerPage={(event) => getLlps({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader',tabIndex=='1'? 'llp-secondary':'llp')}
                                        />
                                         </Paper>
                                         <Paper>
                                        {!llpsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                                </Paper>
                            </>
                        }

                    </div>
                </div>
            </Paper>
            {addEditLlp.modal ?
                <AddEditLLP
                    tabIndex={tabIndex}
                    params={params}
                    addEditLlp={addEditLlp}
                    toggleModalFn={() => setAddEditLlp({ modal: false, llps: [] })}
                    getResponseBack={() => getLlps({}, 'pageLoader',tabIndex=='1'? 'llp-secondary':'llp')}
                    assetInfoData={assetInfoData}
                /> : null
            }
            {addLlp.modal ?
                <AddLLP
                    tabIndex={tabIndex}
                    addLlp={addLlp}
                    toggleModalFn={() => setAddLlp({ modal: false, data: null })}
                    getResponseBack={() => getLlps({}, 'pageLoader',tabIndex=='1'? 'llp-secondary':'llp')}
                /> : null
            }
            {deleteLlp.modal ?
                <DeletePopUp
                    modal={deleteLlp.modal}
                    title='Delete LLP'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteLlp({ modal: false, data: null })}
                    deleteRecordFn={onDeleteLlp}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>

    )
}
export default withRouter(LLPs);